@use "_mantine" as mantine;
@use '../../styles/variables';

.footer {
  margin-top: 64px;
  padding: 64px 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;

  color: var(--mantine-color-gray-6);
  background-color: var(--mantine-color-gray-2);

  .copyright a {
    text-decoration: none;
    color: var(--mantine-primary-color-filled);

    &:hover {
      color: var(--mantine-primary-color-hover);
    }
  }

  .legal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    a {
      font-size: 0.9rem;
      text-decoration: none;
      color: var(--mantine-primary-color-filled);

      &:hover {
        color: var(--mantine-primary-color-hover);
      }
    }
  }

  .socials {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
  }
}
